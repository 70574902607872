.room-card {
    width: 350px;
    height: fit-content;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: all 0.5s ease;
}

.room-card:hover {
    transform: translateY(-10px);
}

.room-card-image {
    height: 200px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
}

.room-card img {
    width: 100%;
    height: 200px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    object-fit: cover;
    object-position: center;
}

.room-card img:hover {
    transform: scale(1.2);
}

.room-card-content {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
}

.room-card-content h2 {
    text-transform: uppercase;
}

.room-card-content p {
    color: #898989;
}

.room-card-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
}

.room-card-price-left h2 span {
    font-size: 16px;
    color: #A0A0A0;
}

.room-card-price-right button {
    background-color: #b81104;
    color: #fff;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    outline: none;
    padding: 5px 10px;
    font-size: 16px;
    border: 1.5px solid #b81104;
    cursor: pointer;
    transition: all 0.2s ease-in;
}

.room-card-price-right button:hover {
    background-color: #fff;
    color: #000;
    border: 1.5px solid #b81104;
}

@media (max-width: 480px) {
    .room-card {
        width: 100%;
        height: fit-content;
    }
}