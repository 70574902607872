.testimonial-section {
    width: 100%;
    height: fit-content;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 10px;
    /* margin-top: 150px; */
}

.swiper-button-next,
.swiper-button-prev {
    color: #fff;
}

.swiper-button-next:active,
.swiper-button-prev:active {
    color: #fff;
}

.slider-card-testimonial {
    width: 100vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #000;
    background-size: cover;
}

.testimonial-content {
    height: fit-content;
    width: 800px;
    background-color: #b81104;
    color: #fff;
    border-radius: 10px;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin: auto;
    gap: 20px;
    margin-bottom: 40px;
}

.testimonial-content p {
    font-size: 18px;
}

.testimonial-content h2 {
    font-size: 30px;
    font-weight: 800;
}

.testimonial-content span {
    font-size: 30px;
    margin-left: 10px;
    margin-right: 10px;
}


/* Responsive */

/*  628px and 768px */
@media (min-width: 628px) and (max-width: 768px) {
    .testimonial-content {
        width: 100%;
        padding: 20px;
    }
}

/*  320px and 628px */
@media (min-width: 320px) and (max-width: 628px) {  
    .testimonial-content {
        width: 100%;
        padding: 20px;
    }

    .testimonial-section {
        height: fit-content;
        /* margin-top: 200px; */
    }

    .slider-card-testimonial{
        height: fit-content;
        width: 100%;
        /* border: 2px solid #000; */
    }

    .swiper-button-next,
    .swiper-button-prev {
        visibility: hidden;
    }
}