.why-choose-section {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 40px 0px;
    background-color: #f6f6f6;
    max-width: 1440px;
    margin: auto;
}

.why-choose-section h1 {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 30px;
}

.why-choose-section p {
    font-style: italic;
    margin-top: 10px;
}

.why-choose-slider {
    width: 100%;
    height: fit-content;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 10px;
}

.why-choose-slider-image {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

.why-choose-slider-image img {
    width: 800px;
    height: 400px;
    border-radius: 20px;
    object-fit: cover;
}

.why-choose-contents {
    height: fit-content;
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.why-choose-contents-box {
    width: 200px;
    height: 200px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    transition: all 0.3s ease-in-out;
}

.why-choose-contents-box:hover {
    transform: translateY(-10px);
}

.why-choose-contents-box img {
    width: 100px;
}

.why-choose-contents-box h2 {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
}

/* Responsive */
@media (min-width: 320px) and (max-width: 768px) {
    .why-choose-section p {
        padding: 0px 0px;
    }

    .why-choose-slider-image img {
        width: 100%;
        height: auto;
    }

    .why-choose-contents-box {
        width: 150px;
        height: 150px;
    }

    .why-choose-contents-box img {
        width: 50px;
    }

    .why-choose-contents-box h2 {
        font-size: 16px;
    }
}